<template>
  <div class="flex flex-col gap-2">
    <p class="text-medium-5">
      {{ $ts('bookAnAppointment.success.title') }}
    </p>
    <p class="text-light-6">
      {{ $ts('bookAnAppointment.success.message') }}
    </p>
  </div>
</template>
