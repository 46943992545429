<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { BookAnAppointmentFormProps } from './BookAnAppointmentForm.props'

const props = withDefaults(defineProps<BookAnAppointmentFormProps>(), {
  isPage: false,
  brand: undefined,
})

const emit = defineEmits<{
  submitBookAnAppointment: [void]
}>()

const { privacyPolicy } = useRouteHelper()
const { checkTokenReCAPTCHA } = useRecaptchaSite()
const { ts } = useI18n()

const {
  bookAnAppointmentForm,
  countriesOptions,
  citiesOptions,
  shopsOptions,
  prefixes,
  handleSubmitBookAnAppointment,
  shopsFromCurrentState,
  route,
  queryParamLoaded,
} = await useBookAnAppointment(props.brand)

const maxCharacters = 500
const charactersLength = computed(() => {
  if (bookAnAppointmentForm.value)
    return `${bookAnAppointmentForm.value.message?.length ?? 0}/${maxCharacters}`
})

const handleSubmit = async () => {
  await checkTokenReCAPTCHA('bookAnAppointment')
  handleSubmitBookAnAppointment()

  emit('submitBookAnAppointment')
}

const cityOrPrefectureLabel = computed(() =>
  bookAnAppointmentForm.value?.country !== 'JP'
    ? ts('bookAnAppointment.form.labels.city')
    : ts('bookAnAppointment.form.labels.prefecture')
)
</script>

<template>
  <div class="flex flex-col gap-10 pb-6">
    
<FormKitLazyProvider config-file="true">
<div v-if="!isPage">
      {{ $ts('bookAnAppointment.subTitle') }}
    </div>
    <FormKit
      :id="formId"
      v-model="bookAnAppointmentForm"
      type="form"
      :classes="{
        messages: 'hidden',
        actions: 'hidden',
      }"
      @submit="handleSubmit"
    >
      <div class="flex flex-col gap-10">
        <div :class="[isPage ? 'gap-0' : 'gap-6', 'flex flex-col']">
          <InputsSelect
            :id="`${formId}-country`"
            :label="$ts('bookAnAppointment.form.labels.state')"
            role="combobox"
            name="country"
            :options="countriesOptions ?? []"
          />
          <InputsSelect
            :id="`${formId}-city`"
            :label="cityOrPrefectureLabel"
            role="combobox"
            name="city"
            :options="citiesOptions"
            :disabled="
              citiesOptions.length <= 0 || bookAnAppointmentForm!.country === ''
            "
            validation="required"
            :validation-messages="{
              required: `${$ts('bookAnAppointment.form.labels.city')} ${$ts(
                'isRequired'
              )}`,
            }"
            validation-visibility="submit"
          />
          <InputsSelect
            :id="`${formId}-shop`"
            :label="$ts('bookAnAppointment.form.labels.shop')"
            role="combobox"
            name="shop"
            :options="shopsOptions"
            :disabled="
              shopsOptions.length <= 0 || bookAnAppointmentForm!.city === ''
            "
            validation="required"
            :validation-messages="{
              required: `${$ts('bookAnAppointment.form.labels.shop')} ${$ts(
                'isRequired'
              )}`,
            }"
            validation-visibility="submit"
          />
        </div>
        <div
          v-show="bookAnAppointmentForm!.shop !== ''"
          class="bg-background-off flex flex-col gap-6 p-6"
        >
          <p class="text-medium-5 uppercase">
            {{ $ts('bookAnAppointment.form.title') }}
          </p>
          <div :class="[isPage ? 'gap-0' : 'gap-6', 'flex flex-col']">
            <div
              :class="[
                isPage
                  ? 'flex-col md:flex-row md:gap-6'
                  : 'flex-row gap-6 lg:flex-col lg:gap-0 xl:flex-row xl:gap-6',
                'flex justify-between',
              ]"
            >
              <InputsTextFields
                :label="$ts('bookAnAppointment.form.labels.firstName')"
                name="firstName"
                validation="required"
                :validation-messages="{
                  required: `${$ts(
                    'bookAnAppointment.form.labels.firstName'
                  )} ${$ts('isRequired')}`,
                }"
                validation-visibility="submit"
              />

              <InputsTextFields
                :label="$ts('bookAnAppointment.form.labels.lastName')"
                name="lastName"
                validation="required"
                :validation-messages="{
                  required: `${$ts('bookAnAppointment.form.labels.lastName')} ${$ts(
                    'isRequired'
                  )}`,
                }"
                validation-visibility="submit"
              />
            </div>

            <InputsTextFields
              :label="$ts('bookAnAppointment.form.labels.email')"
              name="email"
              validation="required"
              :validation-messages="{
                required: `${$ts('bookAnAppointment.form.labels.email')} ${$ts(
                  'isRequired'
                )}`,
              }"
              validation-visibility="submit"
            />

            <div class="flex justify-between gap-6">
              <div class="w-2/5">
                <InputsSelect
                  id="phonePrefix"
                  :label="$ts('bookAnAppointment.form.labels.phonePrefix')"
                  role="combobox"
                  autocomplete="tel-country-code"
                  name="phonePrefix"
                  :options="prefixes"
                />
              </div>
              <div class="w-3/5">
                <InputsTextFields
                  :label="$ts('bookAnAppointment.form.labels.phoneNumber')"
                  name="phoneNumber"
                  type="mask"
                  autocomplete="tel-national"
                  mask="{#|repeat}"
                  show-mask="false"
                  validation="required"
                  :validation-messages="{
                    required: `${$ts('bookAnAppointment.form.labels.phoneNumber')} ${$ts(
                      'isRequired'
                    )}`,
                  }"
                />
              </div>
            </div>
            <FormKit
              type="textarea"
              :label="$ts('bookAnAppointment.form.labels.message')"
              name="message"
              :help="charactersLength"
              :validation="`length:0,${maxCharacters}`"
              :classes="{
                outer: 'bg-primitives-white',
                label: 'text-primitives-black',
              }"
            />
          </div>
          <div v-if="isPage" class="flex flex-wrap justify-between gap-6">
            <p class="text-book-7 w-1/2 grow">
              {{ $ts('bookAnAppointment.disclaimerRecaptcha') }}
            </p>
            <div class="grow">
              <AtomsCta anatomy="primary" :full-width="true">
                <template #label>
                  {{ $ts('bookAnAppointment.buttons.bookAppointment') }}
                </template>
              </AtomsCta>
            </div>
          </div>
          <div v-if="isPage" class="text-book-7">
            <p>{{ $ts('bookAnAppointment.disclaimerServiceRequested') }}</p>
            <p>{{ $ts('bookAnAppointment.disclaimerPersonalData') }}</p>
            <p>
              {{ $ts('bookAnAppointment.privacyPolicyFirstPart') }}
              <MoleculesLinkWrapper anatomy="link" :to="privacyPolicy">
                <template #label>
                  {{ $ts('bookAnAppointment.privacyPolicyLink') }}
                </template>
              </MoleculesLinkWrapper>
              {{ $ts('bookAnAppointment.privacyPolicySecondPart') }}
            </p>
          </div>
        </div>
      </div>
    </FormKit>
    <div v-if="!isPage" class="text-light-6 flex flex-col gap-6">
      <div>
        <p>{{ $ts('bookAnAppointment.disclaimerServiceRequested') }}</p>
        <p>{{ $ts('bookAnAppointment.disclaimerPersonalData') }}</p>
        <p>
          {{ $ts('bookAnAppointment.privacyPolicyFirstPart') }}
          <MoleculesLinkWrapper anatomy="link" :to="privacyPolicy">
            <template #label>
              {{ $ts('bookAnAppointment.privacyPolicyLink') }}
            </template>
          </MoleculesLinkWrapper>
          {{ $ts('bookAnAppointment.privacyPolicySecondPart') }}
        </p>
      </div>
      <p>
        {{ $ts('bookAnAppointment.disclaimerRecaptcha') }}
      </p>
    </div>
</FormKitLazyProvider>

  </div>
</template>
